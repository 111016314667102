@import "./variables.scss";

.commonStyle {
  text-transform: capitalize;
}

.ads-title {
  @extend .commonStyle;
}

.ads-loaction {
  font: {
    size: 16px;
    weight: 500;
  }

  color: #5b7194;
  text-transform: uppercase;
}

.ads-content {
  font-size: 16px;
  @extend .commonStyle;
}

.ads-description {
  @include d-flex;
  margin: 20px 0px;
  @extend .commonStyle;
  font-size: 15px;
  color: rgb(102, 102, 102);

  :nth-child(2) {
    color: black !important;
    padding: 0px 10px;
    font-weight: 500;
  }
}

.ads-apply-det-btn {
  margin-bottom: 20px !important;
  padding: 10px 70px !important;
  @extend .commonStyle;
  border-radius: 5px !important;
  border: none !important;
  background-color: $primary !important;
  color: $light !important;

  &:hover {
    background-color: $primary_hover !important;
  }
}

.ads-tabs-wrapper {
  .nav-item,
  .nav-link {
    color: black;
    border: none !important;
  }

  .nav-link.active {
    border: none !important;
    color: black;
    border-bottom: 2px solid $primary !important;
  }
}
