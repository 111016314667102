.preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.preloader-cards {
    display: flex;
    justify-content: space-between;
    width: 210px; /* Adjust based on the number of cards and their width */
}

.preload-card {
    width: 30px;
    height: 40px;
    background-color: #00B9FF;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    animation: flip 2s infinite;
}

.preload-card:nth-child(1) {
    animation-delay: 0s;
}

.preload-card:nth-child(2) {
    animation-delay: 0.3s;
}

.preload-card:nth-child(3) {
    animation-delay: 0.6s;
}

.preload-card:nth-child(4) {
    animation-delay: 0.9s;
}

.preload-card:nth-child(5) {
    animation-delay: 1.2s;
}

.preload-card:nth-child(6) {
    animation-delay: 1.5s;
}

.preload-card:nth-child(7) {
    animation-delay: 1.8s;
}

@keyframes flip {
    0%, 100% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
}