@import "./variables.scss";

.featued-ads-title {
  font: {
    size: 21px;
    weight: 600;
  }
}

.featued-ads-container {
  .nav-tabs {
    border: none !important;
    @include d-flex;
    justify-content: center;

    .nav-item:first-child {
      .nav-link {
        border-left: 1px solid black;
      }
    }

    .nav-item:last-child {
      .nav-link {
        border-right: 1px solid black;
      }
    }

    .nav-item {
      .nav-link {
        border: 1px solid black;
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        color: black;
        padding: 8px 30px;

        font: {
          size: 14px;
          weight: 600;
        }
      }

      &:hover {
        // border-top: .1px solid black !important;
        // border-bottom: 1px solid black !important;
      }

      .active {
        background-color: $primary !important;
        border: 1px solid $primary !important;
        color: white !important;
      }
    }
  }
}

.card-wrapper {
  flex: 1 1 21rem;
  display: flex;
  flex-diraction: colum;
  align-self: strech;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 10px 0px;
    flex: 1;

    .card-image {
      height: 100%;
      width: 100%;
      // object-fit: cover;
      object-fit: contain;
      cursor: pointer;
    }

    .card-body {
      a {
        text-decoration: none;
        color: black;
      }

      .card-title {
        text-transform: capitalize;
        cursor: pointer;

        font: {
          size: 16px;
          weight: 600;
        }
      }

      .card-spaks {
        @include d-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          color: gray;
          text-transform: capitalize;
          font: {
            size: 11px;
            weight: 500;
          }
        }

        p {
          text-transform: capitalize;

          font: {
            size: 12px;
            weight: 600;
          }
        }
      }

      .card-text {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-footer {
      @include d-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: white;
      margin-top: auto !important;

      p {
        font: {
          size: 11px;
          weight: 600;
        }
        color: #00000080;
      }

      .whislist {
        color: $primary;
        cursor: pointer;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        padding: 5px;

        &:hover {
          background-color: lightgray;
        }
      }
    }

    @media (max-width: 992px) {
      .card-image {
        height: 170px;
        background-position: top;
      }
    }
  }
}

////////////////////////////////////////////


.card-wrapper {
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  
  height: 100%;

}

.card-image {
  height: 100%;
  object-fit: cover;
}

.card-body {
  flex: 1;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.filters {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.filters input[type="checkbox"] {
  margin-right: 10px;
}

.filter-title {
  cursor: pointer;
  // background-color: red;
  // padding: 5px;
  // color: white;
  font-weight: bold;
  // display: block;
  // width: 100%;
  // margin-bottom: 8px;
  // border-radius: 1rem;
  // box-shadow: 0px 0px 2px 5px black;
  // border: 2px solid ;

}

.filters-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filters-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-title {
  cursor: pointer;
  // background-color: red;
  // padding: 0.5rem;
  // color: white;
  // margin-bottom: 0.5rem;
}

.filter-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filters {
  display: flex;
  align-items: center;
}

.filters input {
  margin-right: 0.5rem;
}
