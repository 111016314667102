@import "./variables.scss";

.input-common-style {
  padding: 10px !important;
  margin: 10px 0px;
  border: 1px solid gray !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 100%;
  border-radius: 5px;

  font: {
    size: 13px !important;
    weight: 400 !important;
  }
}

.post-title {
  color: #333;

  font: {
    size: 15px;
    weight: 400;
  }
}

.post-input,
.select-input {
  @extend .input-common-style;
}

.sc-aXZVg {
  max-width: 100% !important;
}

.sub-roles-container {
  @extend .input-common-style;
  height: 70px;
  overflow-x: auto;
  @include d-flex;
  flex-wrap: wrap;
  .checkbox-wrapper-43 {
    input[type="checkbox"] {
      display: none;
      visibility: hidden;
    }

    label {
      display: inline-block;
    }

    .check {
      cursor: pointer;
      position: relative;
      margin: auto;
      width: 18px;
      height: 18px;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);

      &:before {
        content: "";
        position: absolute;
        top: -15px;
        left: -15px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(34, 50, 84, 0.03);
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      svg {
        position: relative;
        z-index: 1;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #c8ccd4;
        stroke-width: 1.5;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;

        path {
          stroke-dasharray: 60;
          stroke-dashoffset: 0;
        }

        polyline {
          stroke-dasharray: 22;
          stroke-dashoffset: 66;
        }
      }

      &:hover:before {
        opacity: 1;
      }

      &:hover svg {
        stroke: #4285f4;
      }
    }

    input[type="checkbox"]:checked + .check svg {
      stroke: #4285f4;

      path {
        stroke-dashoffset: 60;
        transition: all 0.3s linear;
      }

      polyline {
        stroke-dashoffset: 42;
        transition: all 0.2s linear;
        transition-delay: 0.15s;
      }
    }
  }

  .checkbox-name {
    margin: 0px 5px;
    text-transform: capitalize;

    font {
      size: 15px;
    }
  }
}

.checkbox-name {
  margin: 0px 5px;
  text-transform: capitalize;

  font: {
    size: 13px;
  }
}

.post-sub-category {
  @include d-flex;
  flex-wrap: wrap;
  .checkbox-wrapper-46 {
    margin: 10px 0px;
  }
  
  .subCategory-name {
    font-size: 15px;
    margin-right: 15px;
  }

  .checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  .checkbox-wrapper-46 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .checkbox-wrapper-46 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-46 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098a9;
    transition: all 0.2s ease;
  }
  .checkbox-wrapper-46 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-46 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #506eec;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }
  .checkbox-wrapper-46 .cbx span:last-child {
    padding-left: 8px;
  }
  .checkbox-wrapper-46 .cbx:hover span:first-child {
    border-color: #506eec;
  }

  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
    background: #506eec;
    border-color: #506eec;
    animation: wave-46 0.4s ease;
  }
  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }

  @keyframes wave-46 {
    50% {
      transform: scale(0.9);
    }
  }
}
