@import "./variables.scss";

.premium-membership {
  background-color: $secoundary;
  margin: 20px 0px;
  color: $light;
  padding: 35px 30px;
  @include d-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;

  .membership-title {
    font: {
      size: 21px;
      weight: 600;
    }
  }

  .membership-contant {
    font: {
      size: 12px;
      weight: 300;
    }

    @media (min-width: 550px) {
      width: 480px;
    }
  }

  .membership-btn {
    background-color: $primary;
    border: none;
    outline: none;
    color: $light;
    padding: 8px 20px;
    border-radius: 5px;
    margin-top: 10px;

    font: {
      size: 14px;
      weight: 500;
    }

    &:hover {
      background-color: $primary_hover;
    }

    @media (min-width: 425px) {
      font: {
        size: 12px;
        weight: 500;
      }
    }
  }
}

.ads-banner {
  text-align: center;
  margin: 20px 0px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .title {
    color: #00000080;
    font: {
      size: 12px;
      weight: 400;
    }
  }

  .main-non-ads {
    height: 200px;
    margin: 10px;
    margin-top: 3px;
    border: 1.5px solid #0000001a;
    @include d-flex;
    justify-content: center;

    .heading {
      color: #00000040;
      font: {
        weight: 600;
      }
    }
  }
}

.ads-banner-image {
  margin: 20px 0px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
}

.category-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 100%;
  border-radius: 6px;
  background-color: $light;
  margin-bottom: 2rem;
  margin-top: -62px !important;
  margin-right: calc(0 * var(--bs-gutter-x)) !important;
  margin-left: calc(0 * var(--bs-gutter-x)) !important;

  .category {
    @include d-flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: black;
    padding: 30px 0px;
    border-right: 1px solid #00000040;
    border-bottom: 1px solid #00000040;
    position: relative !important;
    text-align: center;
    transition: 0.5s !important;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
      object-fit: contain !important;
    }

    .category-name {
      padding: 5px 0px;
      font: {
        size: 14px;
        weight: 600;
      }
    }

    // .load-btn {
    //   background-color: $primary;
    //   color: white;
    //   padding: 5px 20px;
    //   border-radius: 5px;
    //   position: absolute;
    //   bottom: -40px;
    //   display: none;
    //   z-index: 999 !important;

    //   font: {
    //     size: 12px;
    //     weight: 600;
    //   }

    //   &:hover {
    //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    //   }
    // }

    .category-text {
      display: none;
      position: absolute;
      bottom: 5%;
      color: #00000080;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0px 10px;

      font: {
        size: 10px;
        weight: 400;
      }
    }

    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s ease !important;
      border-bottom: 5px solid $primary !important;
      border-radius: 4px;
      background-color: $light;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: none;
      z-index: 999;

      .category-name {
        color: $primary;
      }

      .category-text,
      .load-btn {
        display: block;
      }
    }

    @media (max-width: 992px) {
      width: 33.3% !important;
      padding: 20px 0px;
    }

    @media (max-width: 375px) {
      width: 50% !important;
      padding: 20px 0px;

      .category-name {
        font: {
          size: 12px;
          weight: 600;
        }
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  :last-child {
    border: none !important;
  }
}

.load-btn {
  background-color: $primary;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;

  font: {
    size: 13px;
    weight: 600;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.model-title {
  color: $primary !important;
  text-transform: capitalize;
}

.slick-current {
  border-bottom: 5px solid $primary;
}

.modal-subcategory {
  padding: 10px 5px;
  cursor: pointer;
  border: 1.5px solid $primary;
  border-radius: 5px;
  font-size: 14px;
  transition: transform 0.5s ease-in-out;

  &:hover {
    background-image: linear-gradient(
      to right,
      rgba(219, 219, 219, 0.358),
      rgb(237, 237, 237)
    );
    
    transform: scale(1.1);
    
    color: rgb(1,185,255);
  }
}

/* CSS */

.modal-dialog {
  .modal-content {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.45) inset;
  }
}




//=============================== category container update============
.ads-banners .main-non-adss {
  height: 100px;
  margin: 10px;
  margin-top: 3px;
  border: 1.5px solid rgba(0, 0, 0, 0.1019607843);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ads-banners {
  text-align: center;
  margin: 20px 0px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-left: auto;
}