@import "./variables.scss";

.navbar {
  background-color: $primary;
}

.profile-icon {
  margin: 0px 15px;
}

.auth-status-link {
  // padding: 5px 15px;
  padding: 5px 2px;
  color: $light;
}

.post-add-btn {
  padding: 8px 30px;
  color: $primary;
  background-color: $light;
  border: none;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase !important;

  font: {
    size: 13px;
    weight: 700;
  }

  &:hover {
    background-color: #70d8ff;
    color: $light;
  }
}

@media (max-width: 992px) {
  .profile-icon {
    background-color: $primary !important;
    margin: 0px 0px 15px 0px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .post-add-btn {
    background-color: $primary;
    color: $light;
  }
.auth-status-link{
    color: black;
  }
}
