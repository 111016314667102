// @import "../../styles/variables.scss";

// .main-category {
//   width: 80%;
//   border-radius: 6px;
//   background-color: transparent;
//   margin-bottom: 2rem;
//   margin-top: -62px !important;
//   margin-right: calc(0 * var(--bs-gutter-x)) !important;
//   margin-left: calc(0 * var(--bs-gutter-x)) !important;

//   .category {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     text-decoration: none;
//     color: black;
//     padding: 30px 0px;
//     border-right: 2px solid rgb(223, 208, 208) !important;
//     position: relative !important;
//     text-align: center;
//     transition: 0.5s !important;
//     cursor: pointer;

//     .category-name {
//       padding: 5px 0px;
//       font-size: 14px;
//       font-weight: 600;
//     }

//     .category-text {
//       display: none;
//       position: absolute;
//       bottom: 5%;
//       color: #00000080;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       width: 100%;
//       padding: 0px 10px;
//       font-size: 10px;
//       font-weight: 400;
//     }

//     .load-btn {
//       background-color: #00b9ff;
//       color: white;
//       padding: 5px 20px;
//       border-radius: 5px;
//       position: absolute;
//       bottom: -40px;
//       display: none;
//       z-index: 999 !important;
//       font-size: 12px;
//       font-weight: 600;

//       &:hover {
//         box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//       }
//     }

//     &:hover {
//       .category-name {
//         color: #00b9ff;
//       }

//       .category-text,
//       .load-btn {
//         display: block;
//       }

//       border-right: 2px solid black !important;
//     }
//   }

//   @media (max-width: 992px) {
//     .category {
//       width: 33.3% !important;
//       padding: 20px 0px;
//     }
//   }

//   @media (max-width: 375px) {
//     .category {
//       width: 50% !important;
//       padding: 20px 0px;

//       .category-name {
//         font-size: 12px;
//         font-weight: 600;
//       }

//       &:hover {
//         transform: scale(1.1);
//       }
//     }
//   }

//   .category:last-child {
//     border: none !important;
//   }
// }








@import "../../styles/variables.scss";

.main-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Centers the subcategories horizontally
  align-items: center; // Centers the subcategories vertically
  border-radius: 6px;
  background-color: transparent;
  margin-bottom: 2rem;
  margin-top: -62px !important;
  margin-right: calc(0 * var(--bs-gutter-x)) !important;
  margin-left: calc(0 * var(--bs-gutter-x)) !important;

  .category {
    flex: 0 0 23%; // Adjust the width of the subcategories (4 in a row with some gap)
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: black;
    padding: 30px 0px;
    border-right: 2px solid red !important;
    position: relative !important;
    text-align: center;
    transition: 0.5s !important;
    cursor: pointer;

    .category-name {
      padding: 5px 0px;
      font-size: 14px;
      font-weight: 600;
    }

    .category-text {
      display: none;
      position: absolute;
      bottom: 5%;
      color: #00000080;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0px 10px;
      font-size: 10px;
      font-weight: 400;
    }

    .load-btn {
      background-color: #00b9ff;
      color: white;
      padding: 5px 20px;
      border-radius: 5px;
      position: absolute;
      bottom: -40px;
      display: none;
      z-index: 999 !important;
      font-size: 12px;
      font-weight: 600;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }

    &:hover {
      .category-name {
        color: #00b9ff;
      }

      .category-text,
      .load-btn {
        display: block;
      }

      border-right: 2px solid black !important;
    }
  }

  @media (max-width: 992px) {
    
    .category {
      flex: 0 0 20%; // Adjust the width for smaller screens
      padding: 10px 5px;
      padding-right: 7px;
      margin: 20px 0px;
      margin-left: 0px;
      margin-right: 0px;
    }


  }

@media (max-width: 500px) {
  .category{
    margin-top: 40px !important;
  }
}

  @media (max-width: 375px) {
    .category {
      flex: 0 0 20%; // Adjust the width for even smaller screens
      padding: 2px 0px;
      margin-top: 40px !important;
      .category-name {
        font-size: 12px;
        font-weight: 600;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .category:last-child {
    border: none !important;
  }
}
