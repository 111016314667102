@import "./variables.scss";

.banner {
  border-top: 1px solid #ffffff80;
  background-image: url("../assets/images/hero_banner.svg");
  background-position: center;
  background-size: cover;

  .banner-inner {
    padding: 4rem 0rem;
    color: $light;
    text-align: center;

    .banner-heading {
      font: {
        size: 38px;
        weight: 600;
      }
    }

    .banner-content {
      font: {
        size: 14px;
        weight: 400;
      }
    }

    .banner-input-container {
      @include d-flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      margin: 30px 0px;
      padding-bottom: 4rem !important;

      // :nth-child(1) {
      //   border-top-left-radius: 5px;
      //   border-bottom-left-radius: 5px;
      // }

      .input-section {
        background-color: $light;
        padding: 8px 0px;
        padding-left: 20px;
        @include d-flex;

        input {
          border: none;
          outline: none;
          margin: 0px 5px;
          padding: 5px 0px;
          padding-right: 20px;

          font: {
            size: 13px;
            weight: 400;
          }
        }

        select {
          margin: 0px 5px;
          border: none;
          outline: none;
          padding: 5px 0px;
          padding-right: 60px;

          font: {
            size: 13px;
            weight: 400;
          }
        }

        option {
          padding: 20px !important;
        }

        .search-btn {
          background-color: $primary;
          border: none;
          outline: none;
          color: $light;
          margin-right: 4px;
          border-radius: 5px;
          padding: 7px 15px;
          @include d-flex;

          font: {
            size: 14px;
            weight: 600;
          }

          span {
            margin-left: 8px;
          }
        }
      }

      .btn-input-section {
        padding: 5px 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .br-input-section input {
        border-right: 1.5px solid #00000040;
      }

      @media (max-width: 992px) {
        display: block !important;

        :nth-child(1),
        .btn-input-section {
          border-radius: 0;

          button {
            margin: 10px 0px;
            margin-left: 20px;
          }
        }

        .br-input-section input {
          border: none;
        }

        .input-section {
          input,
          select {
            width: 100%;
            margin-left: 15px;
          }
        }

        :first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        :last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    @media (max-width: 600px) {
      padding: 2rem 0rem !important;

      .banner-heading {
        font: {
          size: 30px;
          weight: 500;
        }
      }

      .banner-content {
        font: {
          size: 12px;
          weight: 400;
        }
      }
    }
  }
}
