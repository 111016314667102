.breadcrumb {
    list-style: none;
    display: flex;
    gap: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  
  .breadcrumb-item {
    display: inline;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #151516;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline;
  }
  