/* .service-report-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  } */
  /* .service-report-form img{
    width: 100%;
    object-fit: cover;
  } */
  /* .service-report-form div{
    display: flex;
    align-items: ;
    justify-content: space-between;
  } */

  
  /* .service-report-form label:nth-child(even)
  {
    background-color: #E5F6FD;
  } */
  .service-report-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    color: #2362A9;
    font-weight: bolder;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  .service-report-form img{
    width: 100%;
    object-fit: cover;
  }
  
  .service-report-form select {
    width: 80vb;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
    border: none;
  }

  .service-report-form input,
  .empty_label{
    /* width: 10vb; */
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
    border: none;
  }

  
  .service-report-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .service-report-form button:hover {
    background-color: #0056b3;
  }
  input{
    /* width: 50px; */
    background-color: transparent;
    display: inline;
    border: none;
  }
   

  /* ================================ */
   
  .service-report-form #customer_details,.service_name{
    text-align: center;
    margin-bottom: 20px;
    color: #365B92;
    font-weight: bolder;
    display: block;
  } 











  

  label{
    width: 100%;
  }
  .service-report-form>div> label{
    /* margin-right: 50%; */
    display: flex;
    flex-direction: row;
  }

  .service-report-form label:nth-child(odd) {
    width: 100%;
    margin-bottom: 10px;
    background-color: #E5F6FD;
  }

  label{
    display: flex;
    flex-direction: row;
    background-color: transparent   ;
  }

  .service-report-form input{
    padding-top: 0px;
    font-size: 20px;
    /* margin: 5px; */
  }
  .service-report-form label{
    padding-top: 3px;
  }