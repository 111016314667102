@import "./variables.scss";

.common-style {
  background-color: $secoundary;
  color: $light;
}

.footer {
  padding: 4rem 0rem;
  @extend .common-style;

  .footer-items {
    padding: 5px 0px;
    text-transform: capitalize;
    color: $light;
    text-decoration: none;
    font: {
      weight: 400;
      size: 13px;
    }
  }

  .footer-contact {
    padding: 5px 0px;

    font: {
      weight: 400;
      size: 13px;
    }
    label {
      max-width: 100px !important;
    }
  }

  .links-title {
    color: $primary;
    font: {
      size: 20px;
      weight: 600;
    }
  }
  .footer-form {
    label {
      font: {
        size: 12px;
        weight: 400;
      }
    }
    .inputs {
      display: flex;
      input {
        padding: 8px 20px;
        height: 40px;
        border-radius: 5px;
        border: none;
        outline: none;
        border: 1.5px solid $primary;
      }
      button {
        margin: 0px 10px;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: $primary;
        color: $light;
        padding: 8px 30px;

        font: {
          size: 14px;
          weight: 600;
        }
      }
    }
  }

  .flow-as {
    @include d-flex;
    margin: 20px 0px;

    label {
      color: $primary;
      font: {
        size: 14px;
        weight: 600;
      }
    }

    .icons {
      img {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 425px) {
    .links-title {
      margin-top: 20px;
    }
    .inputs {
      flex-wrap: wrap;
      input {
        width: 100%;
      }
      button {
        margin: 10px 0px !important;
      }
    }
  }
}

.copy-rights {
  @extend .common-style;
  padding: 20px 0px;
  border-top: 1.5px solid $primary;

  .copy-rights-inner {
    @include d-flex;
    flex-wrap: wrap;
    justify-content: space-between;

    font: {
      size: 13px;
      weight: 400;
    }

    a {
      color: $light;
      text-decoration: none;
      span {
        font: {
          size: 14px;
          weight: 600;
        }
      }
    }

    .privacy-policy {
      padding-right: 10px;
      border-right: 1px solid white;
    }

    .terms-condition {
      padding-left: 10px;
    }
  }
}
