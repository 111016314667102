@import "./variables.scss";

.common-style {
  @include d-flex;
  justify-content: center;
  height: 100%;
  width: 50%;
}

.auth-container {
  @include d-flex;
  height: auto;

  .auth-content {
    height: 100vh;
    @extend .common-style;
    background-image: linear-gradient(to right, #00bbffd7, #00bbffd7),
      url("../assets/images/AuthPages_Background_image.svg");
    background-size: cover, contain;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    padding: 40px;

    .boxs {
      width: 362px;
      background-color: $light;
      color: $light;
      @include d-flex;
      flex-direction: column;
      padding: 50px 10px;
      border: 2px solid #44bae6;
      border-radius: 5px;
      background-color: #5fcffbca;

      h1 {
        line-height: 50px;
        letter-spacing: 2%;

        font: {
          weight: 600;
          size: 45px;
        }
      }

      p {
        line-height: 28px;
        letter-spacing: 2%;
        font: {
          weight: 400;
          size: 13px;
        }
      }
    }
  }

  .auth-input-container {
    @extend .common-style;

    .boxs{
      width: 70%;

      .auth-title {
        margin-bottom: 10px;
        font: {
          weight: 600;
          size: 30px;
        }
      }

      p {
        padding-bottom: 5px;
        font: {
          weight: 400;
          size: 12px;
        }
      }

      input {
        width: 100%;
        color: black !important;
        margin-bottom: 10px;
        border: 1.5px solid $primary;
        padding: 10px 15px;
        border-radius: 5px;
        background-color: $light_gray;
        outline: none;

        font: {
          weight: 400;
          size: 14px;
        }
      }

      input::placeholder {
        color: #b6bcbf;
      }

      .password-input {
        @include d-flex;
        margin-bottom: 10px;

        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: 0;
          border-right: none;
        }

        button {
          background-color: $light_gray;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          outline: none;
          border: 1.5px solid $primary;
          border-left: none;
          padding: 8.5px 15px;
          position: relative;

          .eye-icon {
            font-size: 20px;
            color: #b6bcbf;
          }
        }

        button:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 1px;
          height: 30px;
          background-color: #b6bcbf;
        }
      }

      .options {
        @include d-flex;
        justify-content: space-between;

        input {
          width: 10%;
          margin: 0;
        }

        label {
          width: 120px;
          cursor: pointer;
          padding: 0px 5px;
          color: gray;

          font: {
            size: 13px;
            weight: 400;
          }
        }

        a {
          color: #00b9ff;
          font: {
            size: 13px;
            weight: 400;
          }
          &:hover {
            color: $primary_hover;
          }
        }
      }

      .submit-btn {
        width: 100%;
        margin: 10px 0px;
        padding: 7px;
        border: none;
        outline: none;
        color: $light;
        background-color: $primary;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;

        &:hover {
          background-color: $primary_hover;
        }
      }

      .page-purpose {
        text-align: center;
        text-transform: capitalize;
        color: gray;
        position: relative;

        font: {
          size: 12px;
          weight: 400;
        }

        span:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 100px;
          height: 0.5px;
          background-color: #b6bcbf;
        }

        span:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          width: 100px;
          height: 0.5px;
          background-color: #b6bcbf;
        }
      }

      .auth-social-media-links {
        @include d-flex;
        justify-content: space-between;
        margin-top: 10px;

        .link-box {
          border: 1.3px solid #00000040;
          border-radius: 5px;

          img {
            padding: 8px 30px;
          }
          &:hover {
            background-color: $light_gray;
          }
        }
      }

      .sugges-link {
        text-align: center;
        margin: 20px 0px;
        
        p {
          font: {
            size: 14px;
          }

          a {
            color: #00b9ff;
            &:hover {
              color: $primary_hover;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .auth-content {
      display: none;
    }

    .auth-input-container {
      width: 100%;
      margin: 40px 20px;
    }
  }

  @media (max-width: 426px) {
    .auth-input-container {
      .boxs {
        width: 100%;
      }
    }
  }
}
