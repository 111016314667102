$primary: #00b9ff;
$primary_hover: #70d8ff;
$secoundary: #012233;
$light: white;
$light_gray: #f2fbff;

@mixin d-flex {
  display: flex;
  align-items: center;
}
