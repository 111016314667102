.slider-item {
    cursor: pointer;
    transition: transform 0.3s;
    text-decoration: none; // Ensure text has no underline
  
    &.slick-current {
      transform: scale(1.1);
      border: 2px solid #007bff; // Add your preferred active border style
    }
  
    img {
      margin-bottom: 10px;
      text-decoration: none; // Ensure image has no underline
    }
  
    p {
      margin: 0;
      text-decoration: none; // Ensure paragraph text has no underline
    }
  }

  .slick-list>.slick-track>.slick-active{
    border-bottom:none;
  }

  .modal-body>.slider-container>.slick-slider>.slick-prev:before,.modal-body>.slider-container>.slick-slider>.slick-next:before{
    font-size: 20px;  
    // color: black;
    color: #00B9FF;
  }
  
  .modal-body>.slider-container>.slick-slider>.slick-next:before{
    // padding-right: 10px;
    position: relative;
    left: 20px;
  }
  .modal-body>.slider-container>.slick-slider>.slick-prev:before{
    // padding-right: 10px;
    position: relative;
    right: 20px;
  }

  #catansubcat>.modal-content{
    box-shadow: none !important;

  }


