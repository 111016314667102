.filters-container {
    margin: 20px;
  }
  
  .filter-title {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .filter-list {
    margin-top: 10px;
  }
  
  .filters {
    display: flex;
    align-items: center;
  }
  
  .filters input {
    margin-right: 10px;
  }
  