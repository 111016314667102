// Basic container styling
.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

// Styling for the OTP input container
.otp-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

// Title and instructions
.otp-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.otp-input-container p {
  color: #666;
  margin-bottom: 20px;
}

// Styling for OTP input fields
.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px !important;
  justify-content: center;
}

.otp-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border: 2px solid #ccc;
  // border-radius: 6px;
  margin: 0 5px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 0;
  border: none;
  border-bottom: 2px solid #d8d8d8 !important;
  margin: 10px !important;
  width: 25px !important;
}

.otp-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

// Success and error message styles
.success-message {
  color: #28a745;
  font-size: 14px;
  margin-top: 10px;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}

// Submit button styling
.submit-btn {
  background-color: #00B9FF;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  justify-content: center;
}

.submit-btn:hover {
  background-color: #0056b3;
}
.link-box {
  margin: 20px 12px;
}

.cancle-btn {
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  justify-content: center;
}

.cancel-otp-arrow{
 border-radius: 50%;
 border-color: black;
 border-width: 1px;
 border-style: solid; 
 margin-right: 10px;
}

.cancel-otp-arrow:hover {
  color: #d4212a;
  }


