@import "./variables.scss";

.profile {
  .nav {
    flex-direction: column;
    .nav-item {
      color: $primary !important;
      background-color: #ececec;
      margin-bottom: 5px;

      button {
        color: black !important;
        //   width: 100%;
        font: {
          size: 15px;
          weight: 500 !important;
        }

        &.active {
          background-color: transparent;
          color: $primary !important;
        }
      }

      @media (min-width: 768px) {
        width: 100% !important;
      }
    }

    @media (max-width: 768px) {
      // background-color: red;
      flex-direction: row !important;
      justify-content: space-between;
    }
  }

  .nav-item-menu {
    width: 100%;
    margin-top: -5px;
    border: 0.5px solid #ececec;
    margin-bottom: 5px;

    font: {
      size: 13px;
      weight: 500 !important;
    }

    button {
      color: black !important;
      background-color: transparent !important;

      font: {
        size: 15px;
        weight: 500 !important;
      }

      &.active {
        background-color: transparent;
        color: $primary !important;
      }
    }
  }

  .tab-content {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
  }

  .ads-details {
    color: black !important;
    .ads-title {
      font-size: 15px;
      text-transform: capitalize;
      color: #0066cc;

      span {
        font-weight: 400;
        font-size: 14px;
        color: black;
      }
    }

    .ads-location {
      text-transform: capitalize;
      font-size: 13px;
    }

    .ads-details-btn {
      color: #0066cc;
      border: none;
      background-color: transparent;
    }
  }

  .actions {
    border-left: 1px solid black;
    color: black;
    ul {
      li {
        list-style-type: none;
        cursor: pointer;
        color: $primary;

        &:hover {
          color: $primary_hover;
        }
      }
    }
  }

  .my-ads-container {
    height: 400px;
    overflow-y: auto;
  }

  .profile-title {
    background-color: #ececec;
    color: black !important;
    padding: 10px;
    font-size: 23px;
    font-weight: 500;
  }
}
