@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables.scss";

* {
  font-family: "Poppins", sans-serif;
}

.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);

    .button-82-front {
      transform: translateY(-6px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .button-82-shadow {
      transform: translateY(4px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }
  }

  &:active {
    .button-82-front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .button-82-shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  .button-82-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }

  .button-82-edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      $primary_hover 0%,
      rgba($primary_hover, 0.8) 8%,
      rgba($primary_hover, 0.8) 92%,
      $primary_hover 100%
    );
    // background: linear-gradient(
    //   to left,
    //   hsl(340deg 100% 16%) 0%,
    //   hsl(340deg 100% 32%) 8%,
    //   hsl(340deg 100% 32%) 92%,
    //   hsl(340deg 100% 16%) 100%
    // );
  }

  .button-82-front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 13px !important;
    color: white;
    background: $primary;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

    @media (min-width: 768px) {
      padding: 12px 27px;
      border-radius: 12px;
    }
  }
}

.bread-crumb {
  margin: 10px 0px;
  a {
    text-decoration: none;
    a {
      color: gray;
      font-size: 13px;
      text-transform: capitalize !important;
      text-decoration: none;
      // cursor: text;
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 12px;
  height: 100%;
  @include d-flex;
  align-items: center;
  justify-content: center;
}

.ads-submit-btn {
  background-color: $primary;
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 4px 10px;
  text-transform: uppercase;

  &:hover {
    background-color: $primary_hover;
  }
}

.primary-btn {
  background-color: $primary;
  border: none;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  &:hover {
    background-color: $primary_hover;
  }
}

.filepond--credits {
  display: none;
}

.animation {
  [aria-label="animation"] {
    @media (max-width: 375px) {
      width: 300px !important;
      height: 300px !important;
    }
  }
}

.search-input-container {


  .search-input {
    padding: 8px 20px;
    border-radius: 30px;
    font-size: 14px;
    width: 100%;
    border: none;
    outline: none;
    text-transform: capitalize;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      // position: absolute;
      margin-left: auto;
  }

  .search-results {
    position: absolute;
    width: 100%;
    text-align: start !important;
    padding: 2px 10px;

    ul {
      margin-bottom: 0 !important;
      padding: 0px !important;
      position: relative !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      background-color: $light;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: 999 !important;

      li {
        list-style-type: none;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 15px;
        @include d-flex;
        justify-content: space-between;
        text-transform: capitalize;

        .result-icon {
          font: {
            size: 15px;
            weight: 700;
          }
        }

        &:hover {
          background-color: rgba(211, 211, 211, 0.548);
        }
      }
    }
  }

  @media (max-width: 720px) {
    margin: 20px 0px;
    width: 100%;
  }
}

.filters {
  .filters-options {
    .filter-title {
      text-transform: capitalize;
      margin: 5px 0px;
    }

    .filters {
      font-size: 15px;
      text-transform: capitalize;
      input {
        margin: 0px 10px;
      }
      label {
        cursor: pointer;
      }
    }

    .filter-search-input {
      outline: none;
      border: none;
      border-radius: 20px;
      font-size: 13px;
      padding: 6px 12px;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
  }
}
